.team1 {
  font-family: "Montserrat", sans-serif;
	color: #8d97ad;
  font-weight: 300;
}

.team1 h1, .team1 h2, .team1 h3, .team1 h4, .team1 h5, .team1 h6 {
  color: #3e4555;
}

.team1 .font-weight-medium {
	font-weight: 500;
}

.team1 .bg-light {
    background-color: #f4f8fa !important;
}

.team1 .subtitle {
    color: #8d97ad;
    line-height: 24px;
}

.team1 .pro-pic {
  min-height: 200px;
}

.team1 .pro-pic .card-img-overlay {
  background: rgba(26, 139, 243, 0.87);
  display: none;
}

.team1 .pro-pic .card-img-overlay ul {
  top: 50%;
}

.team1 .pro-pic .card-img-overlay ul li a {
  -webkit-transition: 0.1s ease-in;
  -o-transition: 0.1s ease-in;
  transition: 0.1s ease-in;
}

.team1 .pro-pic .card-img-overlay ul li a:hover {
  -webkit-transform: translate3d(0px, -5px, 0px);
  transform: translate3d(0px, -5px, 0px);
}

.team1 .pro-pic:hover .card-img-overlay {
  display: block;
}

/* .thumbnail {
  width: 359px !important;
} */

.TriSea-technologies-Switch > input[type="checkbox"] {
  display: none;   
}

.TriSea-technologies-Switch > label {
  cursor: pointer;
  height: 0px;
  position: relative; 
  width: 40px;  
}

.TriSea-technologies-Switch > label::before {
  background: rgb(0, 0, 0);
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  content: '';
  height: 16px;
  margin-top: -8px;
  position:absolute;
  opacity: 0.3;
  transition: all 0.4s ease-in-out;
  width: 40px;
}
.TriSea-technologies-Switch > label::after {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  content: '';
  height: 24px;
  left: -4px;
  margin-top: -8px;
  position: absolute;
  top: -4px;
  transition: all 0.3s ease-in-out;
  width: 24px;
}
.TriSea-technologies-Switch > input[type="checkbox"]:checked + label::before {
  background: inherit;
  opacity: 0.5;
}
.TriSea-technologies-Switch > input[type="checkbox"]:checked + label::after {
  background: inherit;
  left: 20px;
}

.timeline {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.timeline-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  background-color: #f9f9f9;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.timeline-content h3 {
  font-size: 1.5em;
  margin-bottom: 15px;
}

.timeline-media {
  margin: 15px 0;
  width: 100%;
}

.timeline-media img,
.timeline-media video {
  width: 100%;
  max-height: 400px; /* Limite la hauteur maximale pour les images */
  height: auto;
  border-radius: 8px;
  object-fit: cover; /* Pour s'assurer que l'image couvre son conteneur */
}

.timeline-content p {
  font-size: 1em;
  line-height: 1.5;
  margin-bottom: 10px;
}

.timeline-date {
  font-size: 0.9em;
  color: #888;
}


.error-message {
  color: red;
}


.back {
  background: #e2e2e2;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
}

.div-center {
  width: 400px;
  height: 400px;
  background-color: #fff;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  overflow: auto;
  padding: 1em 2em;
  border-bottom: 2px solid #ccc;
  display: table;
}

div.content {
  display: table-cell;
  vertical-align: middle;
}

.card-container {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin-top: 20px;
  background-color: #fff;
}

.card-header {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.card-content {
  font-size: 14px;
}

.card-section {
  margin-top: 20px;
}

.card-section-nk {
  margin-top: 15px;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.card-footer {
  display: flex;
  justify-content: space-between; /* Espace entre les boutons */
  padding: 10px; /* Espacement autour des boutons */
}


.video-card {
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  overflow: hidden;
}
.video-card .card-body {
  padding: 15px;
}
.video-card .card-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}
.video-card .card-text {
  font-size: 14px;
  color: #555;
}

@media (max-width: 768px) {
  .timeline-card {
      flex-direction: column; /* Les cartes se positionnent en colonne sur les petits écrans */
      align-items: flex-end; /* Les cartes se rapprochent de la timeline */
      width: 100%; /* Largeur totale pour les cartes */
      justify-content: flex-start; /* Les cartes se rapprochent de la ligne */
      margin-bottom: 30px; /* Espacement réduit pour les petits écrans */
  }
}













.timeline {
  position: relative;
  margin: 20px 0;
}
.timeline:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 2px;
  background: #ddd;
  transform: translateX(-50%);
}
.timeline-item {
  position: relative;
  margin-bottom: 20px;
}
.timeline-item.left {
  left: 0;
}
.timeline-item.right {
  left: 50%;
}
.timeline-item.left .thumbnail {
  margin-right: 15px;
}
.timeline-item.right .thumbnail {
  margin-left: 15px;
}
.thumbnail {
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.button-container {
  display: block; /* Par défaut, en mode bloc */
}

.timeline {
  position: relative;
  margin: 20px 0;
}

.timeline-item {
  position: relative;
  padding: 20px 0; /* Espacement entre les éléments */
}

.timeline-item.left {
  padding-left: 30px; /* Espacement pour les éléments à gauche */
  text-align: left; /* Alignement à gauche pour le texte */
}

.timeline-line {
  position: absolute;
  left: 50%; /* Par défaut centré */
  top: 0;
  bottom: 0;
  width: 4px; /* Épaisseur de la tige */
  background: #007bff; /* Couleur de la ligne */
  transform: translateX(-50%);
  z-index: 1; /* S'assurer que la ligne est derrière le contenu */
}

/* Styles pour les appareils mobiles */
@media (max-width: 767px) {
  .timeline-line {
      left: 10px; /* Positionner la tige à gauche sur mobile */
      width: 2px; /* Épaisseur réduite pour mobile */
  }

  .timeline-item {
      padding-left: 0; /* Réduire l'espacement à gauche */
      padding-right: 0; /* Réduire l'espacement à droite */
      margin-bottom: 0; /* Supprimer l'espacement en bas pour la proximité */
  }

  .timeline {
      margin: 0; /* Supprimer la marge du conteneur de la timeline */
  }
}

/* Styles pour améliorer l'apparence */
.thumbnail {
  border: none; /* Supprimer les bordures */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ombre pour un effet de profondeur */
  border-radius: 5px; /* Coins arrondis */
}

.caption h1 {
  font-size: 1.5em; /* Taille de police plus grande pour le titre */
  color: #333; /* Couleur du texte */
  margin-bottom: 10px; /* Espacement sous le titre */
}

.caption p {
  color: #666; /* Couleur plus claire pour le texte */
}

.button-container {
  margin-top: 10px; /* Espacement au-dessus des boutons */
}

.delete-button:hover {
  display: block;
}

.responsive-container {
  display: flex;
  flex-wrap: wrap; /* Les éléments passeront à la ligne si nécessaire */
  gap: 10px; /* Espace entre les images */
}

@media (max-width: 768px) {
  .responsive-container {
      display: block; /* Les enfants s'affichent en bloc */
      max-height: none; /* Supprime la hauteur max */
      overflow-x: visible; /* Pas de défilement horizontal */
  }

  .image-container {
      margin-bottom: 10px; /* Espacement entre les images */
  }

  .image-container img {
      width: 100%; /* Les images prennent la largeur du conteneur parent */
      height: auto; /* Ajuste automatiquement la hauteur */
  }
}

.button-container {
  display: flex; /* Par défaut, affichage en ligne */
  justify-content: space-between;
  gap: 10px; /* Espacement entre les boutons */
}

@media (max-width: 768px) {
  .button-container {
      display: block !important; /* Affichage en bloc sur mobile */
  }

  .button-container button {
      width: 100%; /* Les boutons occupent toute la largeur */
      margin-bottom: 10px; /* Espacement entre les boutons */
  }
}


.back-login {
  background-image: url('images/images/logo.PNG');
  background-size: cover;
}

/* Empile les boutons en mobile */
@media (max-width: 768px) {
  .button-group button {
      display: block;
      width: 100%; /* Prend toute la largeur disponible */
      margin-bottom: 5px; /* Espacement entre les boutons */
  }
  .button-group .btn {
    padding: 4px 4px; /* Réduit la hauteur */
    font-size: 10px; /* Réduit la taille de la police */
}
  .button-group button:last-child {
      margin-bottom: 0; /* Pas de marge pour le dernier bouton */
  }
}





/*  */

.custom-card {
  background-color: rgba(34, 58, 102, 0.2);
  border-radius: 8px;
  padding: 20px;
  margin: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
.card-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}
.day-time {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}

.button-group {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.btn-custom {
  flex: 1;
  margin: 0 5px; /* Espacement entre les boutons */
}


/*  */
.custom-radio {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  user-select: none;
  margin-right: 15px; /* Espacement entre les boutons */
}

.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 50%; /* Arrondi pour le bouton radio */
}

.custom-radio:hover input ~ .checkmark {
  background-color: #ccc;
}

.custom-radio input:checked ~ .checkmark {
  background-color: #5cb85c; /* Couleur lorsque coché */
}

.custom-radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 7px;
  top: 3px;
  width: 5px;
  height: 5px;
  border-radius: 50%; /* Arrondi pour le point */
  background: white; /* Couleur du point à l'intérieur */
}

.custom-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Suggestions */
.suggestions-container {
  margin-top: 20px;
}
.suggestion-item {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
  background: #f9f9f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.suggestion-item:hover {
  background: #f1f1f1;
  border-color: #ccc;
}
.user-info {
  flex: 1;
}
.user-name {
  font-weight: bold;
  color: #337ab7;
}
.user-message {
  font-style: italic;
  color: #555;
}
.btn-delete {
  margin-left: 10px;
}

.panel-primary {
  border-color: rgb(34, 58, 102);
}

.panel-primary>.panel-heading {
  color: #fff;
  background-color: rgb(34, 58, 102);
  border-color: rgb(34, 58, 102);
}



/* timeline dynasty */
.timeline {
  position: relative;
  padding: 20px 0;
  margin-top: 20px;
  list-style: none;
}

.timeline:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 4px;
  background: #ddd;
  transform: translateX(-50%);
}

.timeline > li {
  position: relative;
  margin-bottom: 50px;
}

.timeline > li.timeline-inverted .timeline-panel {
  float: right;
}

.timeline > li .timeline-panel {
  position: relative;
  width: 45%;
  padding: 20px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: left;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.timeline > li .timeline-badge {
  position: absolute;
  top: 20px;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  background: #fff;
  border: 4px solid #22a6b3;
  border-radius: 50%;
  z-index: 100;
  text-align: center;
  line-height: 40px;
  color: #22a6b3;
  font-size: 20px;
}

.timeline > li.timeline-inverted .timeline-badge {
  left: auto;
  right: 50%;
  margin-left: 0;
  margin-right: -25px;
}

.timeline > li .timeline-heading h4 {
  margin-top: 0;
  margin-bottom: 10px;
  font-weight: bold;
  color: #2c3e50;
}

.timeline > li .timeline-footer {
  margin-top: 15px;
  text-align: right;
}

.timeline > li .timeline-footer .btn {
  margin-left: 5px;
}

@media (max-width: 768px) {
  .timeline:before {
    left: 20px; /* Déplace la ligne verticale sur mobile */
  }

  .timeline > li {
    margin-bottom: 20px;
    position: relative;
  }

  .timeline > li .timeline-panel {
    width: calc(100% - 40px);
    float: none;
    margin-left: 40px;
    text-align: left;
  }

  .timeline > li.timeline-inverted .timeline-panel {
    margin-left: 40px;
  }

  .timeline > li .timeline-badge {
    left: 0;
    top: 10px;
    margin-left: 0;
    width: 40px;
    height: 40px;
    line-height: 30px;
    font-size: 16px;
  }

  .timeline > li .timeline-body img {
    height: auto;
  }

  .timeline > li.timeline-inverted .timeline-panel {
    float: left;
  }
  .timeline > li.timeline-inverted .timeline-badge {
    left: 0;
    margin-left: 0;
    margin-right: -25px;
  }
}

.filepond--item {
  width: calc(25% - 0.5em);
}
